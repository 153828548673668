webcoffee-admin-default-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;

    .content-wrapper {
        flex: 1;
        overflow: auto;
        padding: 1.2rem 2.4rem;
    }
}

webcoffee-notifications {
    position: absolute;
    top: 3rem;
    background-color: white;
    right: 0;
    // max-height: 50rem;
    z-index: 1002;
    visibility: hidden;
    opacity: 0;
    width: 900px;
    transition: all 500ms ease-in-out;
    cursor: default;
    height: 500px;
    border-style: solid;
    border-color: #ddd2c5;
    border-width: 5px;
    border-radius: 25px;

    &.active {
        visibility: visible;
        opacity: 1;
    }
}

.notificaiton-action-row {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 18px;

    .filters {
        display: flex;
        align-items: center;
        flex: 1;
        min-width: 0;

        mat-slide-toggle {
            white-space: nowrap;
        }
    }
}

.notification-table-wrapper {
    height: 350px;
    overflow: auto;
}

.notifications-paginator {
    border-radius: 25px;
}

.mat-toolbar.webcoffee-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #c9c8c8;

    &.mat-toolbar {
        position: sticky;
        top: 0;
        z-index: 999;

        &.small {
            height: 50px;
        }

        .mat-toolbar-row.second-row {
            max-height: 0;
            height: 0;
            opacity: 0;
            visibility: hidden;
            transition: all 250ms ease-in-out;

            &.expanded {
                max-height: calc(100vh - 50px);
                visibility: visible;
                height: auto;
                opacity: 1;
            }
        }
    }

    .font-icon {
        position: relative;

        &.small::after {
            content: 'S';
        }
        &.medium::after {
            content: 'M';
        }
        &.large::after {
            content: 'L';
        }
        &.extra-large::after {
            content: 'XL';
        }

        &::after {
            position: absolute;
            display: inline-block;
            right: -10px;
            top: 0;
            font-size: 10px;
            height: 10px;
            line-height: 10px;
            width: 10px;
        }
    }
}

.round-logo {
    height: 40px;
    width: 65px;
    background: url(/assets/images/logo.png) rgba(0, 0, 0, 0.75);
    background-repeat: no-repeat;
    background-size: 60px;
    background-position: center;
    border-radius: 5px;
    box-shadow: 0 0 3px 1px grey;
    transition: box-shadow 750ms ease-in-out;
    margin: 0 10px;

    &:hover {
        box-shadow: 0 0 7px 3px grey;
        cursor: pointer;
    }
}
.p-menuitem-link.p-menuitem-link-active {
    background: #ffffff;

    .p-menuitem-text {
        text-decoration: underline;
    }
}

.p-menubar {
    padding: 0.25rem;

    p-menubarsub {
        flex: 1;
        min-width: 0;
    }

    .p-menuitem {
        .p-submenu-list {
            z-index: 999;
        }

        &.icon-menu-item {
            position: relative;

            .p-menuitem-link {
                padding: 0.5rem 0.25rem;

                angledownicon {
                    display: none;
                }
            }
            .p-submenu-list {
                right: 0;
            }

            .p-badge {
                position: absolute;
                top: 0.5rem;
                right: 0.5rem;
                margin: 0;
                padding: 0 0.25rem;
                height: 1rem;
                min-width: 1rem;
                line-height: 1rem;
            }
        }
    }
}
